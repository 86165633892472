<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :fill="fill"
    :viewBox="viewBox"
    :stroke-width="strokeWidth"
    :stroke="stroke"
    v-html="iconContent"
  ></svg>
</template>
<script setup>
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: {
    type: [Number, String],
    default: 24,
  },
  type: {
    type: String,
    default: 'outline',
  },
})

const iconStore = useIconStore()
const { icons } = storeToRefs(iconStore)

const fill = computed(() => {
  const returnValue =
    props.type === 'outline'
      ? 'none'
      : props.type === 'solid'
      ? 'currentColor'
      : props.type === 'mini'
      ? 'currentColor'
      : null
  return returnValue
})
const viewBox = computed(() => {
  return `0 0 ${props.size} ${props.size}`
})
const strokeWidth = computed(() => {
  return props.type === 'outline' ? 1.5 : null
})
const stroke = computed(() => {
  return props.type === 'outline' ? 'currentColor' : null
})
const iconContent = computed(() => {
  if (!icons.value) return null
  const icon = icons.value.find((icon) => icon.name === props.name)
  if (icon) {
    return icon[props.type]
  } else {
    return null
  }
})
</script>
